import React from "react";
import { VariantProps } from "class-variance-authority";
import { fixedForwardRef } from "shared";
import { IconProps, iconSizesKeys } from "@ds-ui/icons/Icon";
import MUIButton, { ButtonProps as MUIButtonProps } from "@mui/material/Button";
import { ButtonStyles } from "./Button.styles";
import { Spinner } from "../Spinner";

export type ButtonProps = Pick<
  MUIButtonProps,
  "id" | "href" | "children" | "onClick" | "style" | "component" | "type"
> &
  VariantProps<typeof ButtonStyles> & {
    className?: string;
    beforeIcon?: React.ReactNode;
    afterIcon?: React.ReactNode;
    // Don't make testId optional; we need it for Pendo!
    testId: string;
    loading?: boolean;
    loadingPlaceholder?: React.ReactNode;
    size?: "s" | "m" | "l" | "xs";
    fullWidth?: boolean;
  } & {
    target?: string;
  };

export const Button = fixedForwardRef(function CHButton(
  {
    weight = "primary",
    className,
    tone = "positive",
    size = "l",
    disabled,
    beforeIcon,
    afterIcon,
    testId,
    loading,
    loadingPlaceholder,
    fullWidth,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const getStartingNode = () => {
    if (loading) {
      return (
        <span className={"flex "}>
          <Spinner className="flex text-current" size={size} />
        </span>
      );
    }

    if (beforeIcon && React.isValidElement(beforeIcon)) {
      return (
        <span className={"flex "}>
          {React.cloneElement(beforeIcon, {
            size: iconSizesKeys[size],
          } as IconProps)}
        </span>
      );
    }

    return null;
  };

  return (
    <MUIButton
      ref={ref}
      disabled={disabled || loading}
      disableRipple
      data-testid={testId}
      className={ButtonStyles({
        weight,
        className,
        tone,
        size,
        disabled: disabled || loading,
        fullWidth,
      })}
      {...props}
    >
      {getStartingNode()}
      {loading ? loadingPlaceholder ?? props.children : props.children}
      {afterIcon && React.isValidElement(afterIcon) && (
        <span className={"flex "}>
          {
            React.cloneElement(afterIcon, {
              size: iconSizesKeys[size],
            } as IconProps) // TODO: We need to fix this
          }
        </span>
      )}
    </MUIButton>
  );
});
