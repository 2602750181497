import { useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "react-use";
import {
  ThemeOption,
  useUpdateUserPreferences,
  useUserPreferences,
} from "shared/user-preferences/userPreferences";
import constate from "constate";
import { applyTheme } from "@ds-ui/theme";

export const SUPPORT_DARK_THEME_ATTR = "data-support-dark-theme";
const MATCH_MEDIA_DARK = "(prefers-color-scheme: dark)";
export const LS_COLOR_THEME = "ColorTheme";

function useThemeHook() {
  const [hasDarkThemeSupport, setHasDarkThemeSupport] = useState(true);
  const [colorTheme, setColorTheme] = useLocalStorage<ThemeOption>(
    LS_COLOR_THEME,
    ThemeOption.System
  );
  const { data, isSuccess } = useUserPreferences();
  const serverSavedTheme = data?.settings?.theme;
  const hasThemeFromServerUpdatedRef = useRef(false);

  const setDarkThemeSupport = useCallback((supportsDarkTheme: boolean) => {
    document.body.setAttribute(SUPPORT_DARK_THEME_ATTR, supportsDarkTheme ? "true" : "false");
    setHasDarkThemeSupport(supportsDarkTheme);
  }, []);

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences();

  const updateTheme = async (theme: ThemeOption) => {
    setColorTheme(theme);
    await updateUserPreferences({ settings: { ...data?.settings, theme } });
  };

  // Update the theme based on user preferences
  // The user might have changed this on another machine
  useEffect(() => {
    if (isSuccess && !hasThemeFromServerUpdatedRef.current) {
      setColorTheme(serverSavedTheme);

      hasThemeFromServerUpdatedRef.current = true;
    }
  }, [serverSavedTheme, isSuccess, setColorTheme]);

  useEffect(() => {
    if (!hasDarkThemeSupport) {
      applyTheme(ThemeOption.Light);
      return;
    }

    if (colorTheme === ThemeOption.System) {
      applyTheme(
        window.matchMedia(MATCH_MEDIA_DARK).matches ? ThemeOption.Dark : ThemeOption.Light
      );
      return;
    }

    applyTheme(colorTheme);
  }, [colorTheme, hasDarkThemeSupport, serverSavedTheme]);

  return { updateTheme, setDarkThemeSupport, colorTheme, isSuccess };
}

export const [ThemeProvider, useTheme] = constate(useThemeHook);
